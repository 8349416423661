import React from 'react'
import ReactDOM from 'react-dom';
import './index.css';
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/User';
import { ScoresProvider } from './context/Scores';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

export const history = createBrowserHistory({ forceRefresh: true });

ReactDOM.render(
  // <React.StrictMode>
  <Router history={history}>
    <React.Suspense fallback={loading()}>
      <UserProvider>
        <ScoresProvider>
          <App />
        </ScoresProvider>
      </UserProvider>
    </React.Suspense>
  </Router>,
  // </React.StrictMode>,
  document.getElementById('root') || document.createElement('div') /* Dummy root used by tests */,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();
