import React, { useReducer, createContext } from 'react';
import PropTypes from 'prop-types';

const ScoresStateContext = createContext();
const ScoresDispatchContext = createContext();

function scoresReducer(state, action) {
  switch (action.type) {
    case 'SCORES_CHANGED':
      return { ...state, ...action.payload };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function ScoresProvider({ children }) {
  const [state, dispatch] = useReducer(scoresReducer, {
    currentPoints: null,
    committedPoints: null,
    requiredPoints: null,
    doReload: 0,
  });
  return (
    <ScoresStateContext.Provider value={state}>
      <ScoresDispatchContext.Provider value={dispatch}>
        {children}
      </ScoresDispatchContext.Provider>
    </ScoresStateContext.Provider>
  );
}

ScoresProvider.propTypes = {
  children: PropTypes.node,
};

function useScoresState() {
  const context = React.useContext(ScoresStateContext);
  if (context === undefined) {
    throw new Error('useScoresState must be used within a ScoresProvider');
  }
  return context;
}

function useScoresDispatch() {
  const context = React.useContext(ScoresDispatchContext);
  if (context === undefined) {
    throw new Error('useScoresDispatch must be used within a ScoresProvider');
  }
  return context;
}

const changeScores = (dispatch, newScores) => {
  dispatch({
    type: 'SCORES_CHANGED',
    payload: { ...newScores },
  });
};

export {
  ScoresProvider,
  useScoresState,
  useScoresDispatch,
  changeScores,
  ScoresStateContext,
};
