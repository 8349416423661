/* eslint-disable react/jsx-props-no-spreading */
import './styles/App.scss';
import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import useUser from './context/User'
import ToastifyContainer from './components/wrappers/ToastifyContainer'

import { HeaderTitleProvider } from './hooks/useHeaderTitle';

const BaseLayout = React.lazy(() => import('./layout/BaseLayout'));
const Page404 = React.lazy(() => import('./views/Error404'));
const Page500 = React.lazy(() => import('./views/Error500'));
const EventListExternal = React.lazy(() => import('./views/EventListExternal'));
const Login = React.lazy(() => import('./views/Login'));

const toastContainerSettings = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
}

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Wrapper um alle Components herum, damit dass nicht alles in der Render-Methode gemacht werden muss
const BaseComponentWrapper = ({ children }) => {
  const { pathname } = useLocation();
  const containerRef = useRef(null);

  useEffect(() => {
    if (containerRef.current) {
      // containerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [pathname]);

  return (
    <>
      <HeaderTitleProvider>
        <div className="main-container">
          <div id="top" ref={containerRef} />
          <div className="content-container">{children}</div>
        </div>
      </HeaderTitleProvider>
    </>
  );
};

BaseComponentWrapper.propTypes = {
  children: PropTypes.node,
};

const UnauthenticatedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useUser()

  return (
    <Route
      {...rest}
      render={(props) => (!isAuthenticated
        ? (
          <BaseComponentWrapper>
            <Component {...props} />
          </BaseComponentWrapper>
        )
        : (<Redirect to={{ pathname: '/' }} />))}
    />
  )
}

UnauthenticatedRoute.propTypes = {
  component: PropTypes.elementType,
};

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { isAuthenticated } = useUser()

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => (isAuthenticated
        ? (
          <BaseComponentWrapper>
            <Component {...props} />
          </BaseComponentWrapper>
        )
        : (<Redirect to={{ pathname: '/login', state: { from: location } }} />))}
    />
  )
}

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType,
};

const HybridRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => (
      <BaseComponentWrapper>
        <Component {...props} />
      </BaseComponentWrapper>
    )}
  />
);

HybridRoute.propTypes = {
  component: PropTypes.elementType,
};

export const NotFoundRoute = ({ ...rest }) => (
  <Route
    /* eslint-disable react/jsx-props-no-spreading */
    {...rest}
    render={(_props) => <Redirect to="/404" />}
  />
);

function App() {
  return (
    <>
      <ToastifyContainer {...toastContainerSettings} />
      <BrowserRouter>
        <React.Suspense fallback={loading()}>

          <Switch>

            <UnauthenticatedRoute exact path="/login" name="Login" component={Login} />
            <HybridRoute exact path="/404" name="Page 404" component={Page404} />
            <HybridRoute exact path="/500" name="Page 500" component={Page500} />
            <HybridRoute exact path="/external/events" name="Einsatzliste" component={EventListExternal} />
            <AuthenticatedRoute path="/" component={BaseLayout} />
            <NotFoundRoute from="*" to="/404" />

          </Switch>

        </React.Suspense>
      </BrowserRouter>
    </>
  );
}

export default App;
