/*
To change header title:
setHeaderTitle is everywhere available because <HeaderTitleProvider> wraps the whole app
const {setHeaderTitle} = useHeaderTitle()
*/

import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const HeaderTitleContext = createContext({
  headerTitle: 'Home',
  setHeaderTitle: () => {},
});

const HeaderTitleProvider = ({ children }) => {
  const [headerTitle, setHeaderTitle] = useState('Home');

  return (
    <HeaderTitleContext.Provider
      value={{
        headerTitle,
        setHeaderTitle,
      }}
    >
      {children}
    </HeaderTitleContext.Provider>
  );
};

HeaderTitleProvider.propTypes = {
  children: PropTypes.node,
};

const useHeaderTitle = () => useContext(HeaderTitleContext);

export default useHeaderTitle;
export { HeaderTitleProvider };
