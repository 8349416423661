/**
 * Api Host, Url & Port
 */
const apiHost = process.env.NODE_ENV === 'production'
  ? ''
  : 'http://localhost';
const apiPrefix = '/api';
const apiPort = process.env.NODE_ENV === 'production' ? '' : '8080';
const apiUrl = `${apiHost}${
  apiPort ? `:${apiPort}${apiPrefix}` : `${apiPrefix}`
}`;

/**
 * localStorage
 */
const localStorageKey = {
  user: '_u',
}

/**
 * Form specific validation
 */
const mobilePattern = '[0-9]{3} [0-9]{3} [0-9]{2} [0-9]{2}'

/**
 * Saison states
 */
const SaisonStatus = Object.freeze({
  OFFEN: { value: 'OFFEN', text: 'In Vorbereitung' },
  AKTIV: { value: 'AKTIV', text: 'Aktive Saison' },
  ABGESCHLOSSEN: { value: 'ABGESCHLOSSEN', text: 'Abgeschlossene Saison' },
  DEAKTIVIERT: { value: 'DEAKTIVIERT', text: 'DEAKTIVIERT' },
})

export default {
  apiUrl,
  localStorageKey,
  mobilePattern,
  SaisonStatus,
};
