import {
  createContext, useContext, useState,
} from 'react';
import PropTypes from 'prop-types';
import useLocalStorage from '../services/useLocalStorage'
import config from '../config/config'

const UserContext = createContext({
  currentUser: null,
  isAuthenticated: false,
});

const UserProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const { clearLocalStorageItem, saveToLocalStorage } = useLocalStorage()

  const onLogin = (user) => {
    setCurrentUser(user);
    setIsAuthenticated(true)
    saveToLocalStorage(config.localStorageKey.user, user)
  };

  const onLogout = () => {
    setCurrentUser(null);
    setIsAuthenticated(false)
    clearLocalStorageItem(config.localStorageKey.user)
  };

  const isAdmin = () => (currentUser.role === 'ADMIN')

  return (
    <UserContext.Provider
      value={{
        currentUser, isAdmin, onLogin, onLogout, isAuthenticated, setIsAuthenticated, setCurrentUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

UserProvider.propTypes = {
  children: PropTypes.node,
};

const useUser = () => useContext(UserContext);

export default useUser;
export { UserProvider, UserContext };
