const useLocalStorage = () => {
  const saveToLocalStorage = (key, value) => {
    try {
      const stringifiedValue = JSON.stringify(value)
      localStorage.setItem(key, stringifiedValue)
    } catch (error) {
      throw new Error(`Failed to add ${value} to localStorage`)
    }
  }

  const clearLocalStorageItem = (key) => {
    try {
      localStorage.removeItem(key)
    } catch (error) {
      throw new Error(`Failed to remove item with key ${key} from localStorage`)
    }
  }
  const getFromLocalStorage = (key) => {
    let value = null
    try {
      const stringifiedValue = localStorage.getItem(key)
      value = JSON.parse(stringifiedValue)
    } catch (error) {
      // do nothing
    }
    return value
  }

  return {
    saveToLocalStorage,
    clearLocalStorageItem,
    getFromLocalStorage,
  };
}

export default useLocalStorage;
